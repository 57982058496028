/*eslint-disable */
// import SpcmCalenderModel from '../../Model/SpcmCalender'
import request from '../../Utils/curl'

let SpcmCalenders = {
  /**
   * spcmCalendarList
   */
  async spcmCalendarList (context, payload=null) {
    try{
      let post_data = new FormData();  
      if (payload) {
        for(const key in payload) {
          if (payload[key] || typeof payload[key] == 'number') {
            post_data.append(key, payload[key])
          }
        }
      }

      return await request.curl(context, "spcm_calendar_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmCalendarList() and Exception:',err.message)
    }
  },

  /**
   * spcmCalendarList
   */
  async spcmCalendarConsoleList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_calendar_console_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmCalendarList() and Exception:',err.message)
    }
  },

  /**
   * spcmCalendarView
   */
  async spcmCalendarView (context, spcmCalendarId) {
    try {      
      let post_data = new FormData();
      post_data.append('spcm_calendar_id', spcmCalendarId);
      return await request.curl(context, "spcm_calendar_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcmCalendarView() and Exception:",err.message)
    }    
  },

  /**
   * spcmCalendarAdd
   */
  async spcmCalendarAdd (context, spcmCalendarObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmCalendarObj) {
        post_data.append(key, spcmCalendarObj[key]);
      }

      return await request.curl(context, "spcm_calendar_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmCalendarAdd() and Exception:',err.message)
    }
  },

  /**
   * spcmCalendarEdit
   */
  async spcmCalendarEdit (context, spcmCalendarObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmCalendarObj) {
        post_data.append(key, spcmCalendarObj[key]);
      }

      return await request.curl(context, "spcm_calendar_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmCalendarEdit() and Exception:',err.message)
    }
  },

  /**
   * spcmCalendarDelete
   */
  async spcmCalendarDelete (context, spcmCalendarId) {
    try{
      let post_data = new FormData();
      
      post_data.append('spcm_calendar_id', spcmCalendarId);

      return await request.curl(context, "spcm_calendar_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcmCalendarDelete() and Exception:',err.message)
    }
  }
}

export {
  SpcmCalenders
}
